@font-face {
    font-family: "BlackObl";
    font-display:swap;
    src: local("BlackObl"),
        url("./fonts/UniversLTStd-BlackObl.otf") format("truetype");
}

@font-face {
    font-family: "Black";
    font-display:swap;
    src: local("Black"),
        url("./fonts/UniversLTStd-Black.otf") format("truetype");
}

@font-face {
    font-family: "BlackEx";
    font-display:swap;
    src: local("BlackEx"),
        url("./fonts/UniversLTStd-BlackEx.otf") format("truetype");
}

@font-face {
    font-family: "BlackExObl";
    font-display:swap;
    src: local("BlackExObl"),
        url("./fonts/UniversLTStd-BlackExObl.otf") format("truetype");
}

@font-face {
    font-family: "UniversLTStdBold";
    font-display:swap;
    src: local("UniversLTStdBold"),
        url("./fonts/UniversLTStd-Bold.otf") format("truetype");
}

@font-face {
    font-family: "BoldCn";
    font-display:swap;
    src: local("BoldCn"),
        url("./fonts/UniversLTStd-BoldCn.otf") format("truetype");
}

@font-face {
    font-family: "BoldEx";
    font-display:swap;
    src: local("BoldEx"),
        url("./fonts/UniversLTStd-BoldEx.otf") format("truetype");
}

@font-face {
    font-family: "BoldExObl";
    font-display:swap;
    src: local("BoldExObl"),
        url("./fonts/UniversLTStd-BoldExObl.otf") format("truetype");
}

@font-face {
    font-family: "BoldObl";
    font-display:swap;
    src: local("BoldObl"),
        url("./fonts/UniversLTStd-BoldObl.otf") format("truetype");
}

@font-face {
    font-family: "Cn";
    font-display:swap;
    src: local("Cn"),
        url("./fonts/UniversLTStd-Cn.otf") format("truetype");
}

@font-face {
    font-family: "CnObl";
    font-display:swap;
    src: local("CnObl"),
        url("./fonts/UniversLTStd-CnObl.otf") format("truetype");
}

@font-face {
    font-family: "Ex";
    font-display:swap;
    src: local("Ex"),
        url("./fonts/UniversLTStd-Ex.otf") format("truetype");
}

@font-face {
    font-family: "ExObl";
    font-display:swap;
    src: local("ExObl"),
        url("./fonts/UniversLTStd-ExObl.otf") format("truetype");
}

@font-face {
    font-family: "Light";
    font-display:swap;
    src: local("Light"),
        url("./fonts/UniversLTStd-Light.otf") format("truetype");
}

@font-face {
    font-family: "LightCn";
    font-display:swap;
    src: local("LightCn"),
        url("./fonts/UniversLTStd-LightCn.otf") format("truetype");
}

@font-face {
    font-family: "LightCnObl";
    font-display:swap;
    src: local("LightCnObl"),
        url("./fonts/UniversLTStd-LightCnObl.otf") format("truetype");
}

@font-face {
    font-family: "LightObl";
    font-display:swap;
    src: local("LightObl"),
        url("./fonts/UniversLTStd-LightObl.otf") format("truetype");
}

@font-face {
    font-family: "LightUltraCn";
    font-display:swap;
    src: local("LightUltraCn"),
        url("./fonts/UniversLTStd-LightUltraCn.otf") format("truetype");
}

@font-face {
    font-family: "Obl";
    font-display:swap;
    src: local("Obl"),
        url("./fonts/UniversLTStd-Obl.otf") format("truetype");
}

@font-face {
    font-family: "ThinUltraCn";
    font-display:swap;
    src: local("ThinUltraCn"),
        url("./fonts/UniversLTStd-ThinUltraCn.otf") format("truetype");
}

@font-face {
    font-family: "UltraCn";
    font-display:swap;
    src: local("UltraCn"),
        url("./fonts/UniversLTStd-UltraCn.otf") format("truetype");
}

@font-face {
    font-family: "XBlack";
    font-display:swap;
    src: local("XBlack"),
        url("./fonts/UniversLTStd-XBlack.otf") format("truetype");
}

@font-face {
    font-family: "XBlackEx";
    font-display:swap;
    src: local("XBlackEx"),
        url("./fonts/UniversLTStd-XBlackEx.otf") format("truetype");
}

@font-face {
    font-family: "XBlackExObl";
    font-display:swap;
    src: local("XBlackExObl"),
        url("./fonts/UniversLTStd-XBlackExObl.otf") format("truetype");
}

@font-face {
    font-family: "XBlackObl";
    font-display:swap;
    src: local("XBlackObl"),
        url("./fonts/UniversLTStd-XBlackObl.otf") format("truetype");
}

@font-face {
    font-family: "UniversLTStd";
    src: url("./fonts/UniversLTStd.woff") format("woff"),
        url("./fonts/UniversLTStd.woff2") format("woff2");
}

/* PowerGrotesk */

@font-face {
    font-family: "PowerGrotesk-Black";
    font-display:swap;
    src: local("PowerGrotesk-Black"),
        url("./fonts/PowerGrotesk/PowerGrotesk-Black.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-BlackItalic";
    font-display:swap;
    src: local("PowerGrotesk-BlackItalic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-BlackItalic.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-Bold";
    font-display:swap;
    src: url("./fonts/PowerGrotesk/PowerGrotesk-Bold.woff") format("woff"),
    url("./fonts/PowerGrotesk/PowerGrotesk-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "PowerGrotesk-BoldItalic";
    font-display:swap;
    src: local("PowerGrotesk-BoldItalic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-BoldItalic.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-Heavy";
    src: url("./fonts/PowerGrotesk/PowerGrotesk-Heavy.woff") format("woff"),
    url("./fonts/PowerGrotesk/PowerGrotesk-Heavy.woff2") format("woff2");
}
@font-face {
    font-family: "PowerGrotesk-HeavyItalic";
    font-display:swap;
    src: local("PowerGrotesk-HeavyItalic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-HeavyItalic.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-Italic";
    font-display:swap;
    src: local("PowerGrotesk-Italic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-Italic.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-Light";
    src: url("./fonts/PowerGrotesk/PowerGrotesk-Light.woff") format("woff"),
    url("./fonts/PowerGrotesk/PowerGrotesk-Light.woff2") format("woff2");
}
@font-face {
    font-family: "PowerGrotesk-LightItalic";
    font-display:swap;
    src: local("PowerGrotesk-LightItalic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-LightItalic.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-Medium";
    src: url("./fonts/PowerGrotesk/PowerGrotesk-Medium.woff") format("woff"),
    url("./fonts/PowerGrotesk/PowerGrotesk-Medium.woff2") format("woff2");
}
@font-face {
    font-family: "PowerGrotesk-MediumItalic";
    font-display:swap;
    src: local("PowerGrotesk-MediumItalic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-MediumItalic.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-Regular";
    font-display:swap;
    src: local("PowerGrotesk-Regular"),
        url("./fonts/PowerGrotesk/PowerGrotesk-Regular.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-UltraBold";
    font-display:swap;
    src: local("PowerGrotesk-UltraBold"),
        url("./fonts/PowerGrotesk/PowerGrotesk-UltraBold.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-UltraBoldItalic";
    font-display:swap;
    src: local("PowerGrotesk-UltraBoldItalic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-UltraBoldItalic.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-UltraLight";
    font-display:swap;
    src: local("PowerGrotesk-UltraLight"),
        url("./fonts/PowerGrotesk/PowerGrotesk-UltraLight.otf") format("truetype");
}
@font-face {
    font-family: "PowerGrotesk-UltraLightItalic";
    font-display:swap;
    src: local("PowerGrotesk-UltraLightItalic"),
        url("./fonts/PowerGrotesk/PowerGrotesk-UltraLightItalic.otf") format("truetype");
}

@font-face {
    font-family: "TiemposFine-LightItalic";
    src: url("./fonts/Tiemposfine/TiemposFine-LightItalic.woff") format("woff"),
    url("./fonts/Tiemposfine/TiemposFine-LightItalic.woff2") format("woff2");
}

